import React from "react";

export interface IAnimatedButtonProps {
    href: string;
    text: string;
}

export const AnimatedButton: React.FC<IAnimatedButtonProps> = (props) => {
    return (
        <a href={props.href} className="linkToFullView hvr-icon-forward">
            {props.text}
            <i className="fa fa-angle-right btnIcon hvr-icon"></i>
        </a>
    );
};