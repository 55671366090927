import Swal from "sweetalert2";

export default class DialogService {
    public static showSuccess(title: string): void {
        Swal.fire({
            title,
            icon: "success",
            toast: true,
            position: "bottom",
            timer: 2000,
            showConfirmButton: false,
        });
    }
    public static showError(title: string): void {
        Swal.fire({
            title,
            icon: "error",
            toast: true,
            position: "bottom",
            timer: 2000,
            showConfirmButton: false,
        });
    }
    public static async showConfirmationWindow(title: string): Promise<boolean> {
        const result: any = await Swal.fire({
            title: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Да"
        });
        return result.value;
    }
}