import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./slider-animations.css";
import { AnimatedButton } from "./AnimatedButton";
import FirebaseService from "./services/FirebaseService";

export interface IHeaderProps {
  title: string;
  description: string;
  button: string;
  buttonLink: string;
  image: string;
}

export const Header: React.FC<{ data: IHeaderProps[] }> = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container headerWidth">
            <div className="row justify-content-center">
              <div className="col-10 col-md-12 intro-text">
                <Slider autoplay={3000} className="slider-wrapper">
                  {props.data.map((item, index) => (
                    <div
                      key={index}
                      className="slider-content"
                      style={{ background: `url("${item.image}") no-repeat center center` }}
                    >
                      <div className="inner">
                        <h1>{item.title}</h1>
                        {/* <p>{item.description}</p> */}
                        {/* <AnimatedButton href={item.buttonLink} text={item.button} /> */}
                      </div>
                    </div>
                  ))}
                </Slider>
                <div id="startCounters"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
