import React from "react";
import { IBlogPost } from "./models/BlogPost";
import * as $ from "jquery";
import { AnimatedButton } from "./AnimatedButton";

interface IBlogProps {
    fullPreview: boolean;
    blogPosts: IBlogPost[];
}

export const Blog: React.FC<IBlogProps> = (props: IBlogProps) => {
    const contentLenght: number = 440;

    let blogPosts: IBlogPost[] = props.blogPosts;

    if (!props.fullPreview) {
        blogPosts = blogPosts.slice(0, 2);
    }

    const expandBlogPostText = (e) => {
        const targetElement: any = $(e.target);
        const parentElement: any = targetElement.closest(".blogPostTxt");
        const hiddenElement: boolean = parentElement.find(".hidden");
        const trimmedText: any = parentElement.find(".shown");

        if ($(hiddenElement).is(":visible")) {
            $(hiddenElement).slideUp(500);
            $(trimmedText).show();
            $(targetElement).text("Прочети повече");
        } else {
            $(trimmedText).fadeOut(300, () => {
                $(hiddenElement).slideDown(500);
            });
            $(targetElement).text("Прочети по-малко");
        }
    };


    return (
        <div id="blog" className="text-center general-section" data-aos="zoom-in">
            <div className="col-12 section-title">
                <h2>Полезно</h2>
                <hr></hr>
                <p>Информирайте се за последните новини относно подовите настилки.</p>
            </div>
            <div className="container">
                {blogPosts.map((blog, index) =>
                    <div className="row text-left margin-bottom blogPost" key={index}>
                        <div className="col-md-4">
                            <img src={blog.imageSource} />
                        </div>
                        <div className="col-md-8">
                            {/* <img src={blog.imageSource} /> */}
                            <h3>{blog.title}</h3>
                            <div className="blogPostTxt">
                                {
                                    blog.content.length <= contentLenght ?
                                        <p className="shown" dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                                        :
                                        <div>
                                            <p className="shown" dangerouslySetInnerHTML={{ __html: blog.content.substring(0, contentLenght) + "..." }}></p>
                                            <p className="hidden" dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                                            <a className="expand" onClick={expandBlogPostText}>Прочети повече</a>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
                {!props.fullPreview && <AnimatedButton href="/blog" text={"Вижте всички статии"} />}
            </div>
        </div>
    );
};