import React from "react";
import { useEffect } from "react";
import * as $ from "jquery";

export interface IconsProps {
  icon: string;
  title: string;
  text: string;
}

export interface IServiceProps {
  paragraph: string;
  bannerText: string;
  servicesIcons: IconsProps[];
}

export const Services: React.FC<{ data: IServiceProps }> = (props) => {
  return (
    <div>
      <div id="services-banner">{props.data.bannerText ? props.data.bannerText : "loading"}</div>
      <div id="services" className="text-center" data-aos="fade-up">
        <div className="container-fluid">
          <div className="section-title">
            <h2>Услуги</h2>
            <p>{props.data.paragraph}</p>
          </div>
          <div className="row">
            {props.data ? props.data.servicesIcons.map((d, key) =>
              <div className="col-sm-6 col-md-4 col-lg-2" key={key}>
                <div className="service-desc">
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ) : "loading"}
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
