// tslint:disable: no-use-before-declare
// tslint:disable: typedef
import React, { useState, useEffect } from "react";
import { DatabaseModel } from "./models/DatabaseModel";
import DialogService from "./services/DialogService";
import FirebaseService from "./services/FirebaseService";

declare var BSTable: any;
declare var $: any;

export interface IAdministration {
  database: DatabaseModel;
}

const passwordSessionStorageKey = "adminPanelPassword";
const correctPassword = "djudivenci2108";

export const Administration: React.FC<IAdministration> = (props) => {
  const [passwordIsCorrect, setPasswordIsCorrect] = useState<boolean>(false);
  let editableColumns: number[] = [];

  const openKeyForm = (key: string) => {
    $("#divContainer").empty();
    $("#propertyContainer").empty();
    $(`.keyTile`).removeClass("selected");
    $(`.keyTile[json-property=${key}]:eq(0)`).addClass("selected");
    editableColumns = [];
    const sectionValue = props.database[key];
    if (Array.isArray(sectionValue)) {
      buildTable(sectionValue, key);
      initializeTable();
    } else {
      buildJsonObjectEditor(sectionValue);
    }
  };

  const buildTable = (values: any[], propertyKey: string) => {
    const table = document.createElement("table");
    table.id = "bsTable";
    table.className = "table table-striped table-bordered";
    const buttonString = `<button id="new-row-button" class="actionButton">Нов запис</button>`;
    let tableString = buildTableHead(values);
    tableString += buildTableBody(values);
    table.innerHTML = tableString;
    $("#divContainer").append($(table));
    $("#divContainer").append($(buttonString));
    $("#divContainer").attr("jsonproperty", propertyKey);
  };

  const getCellWidthClass = (value: string) => {
    if (value.length > 100) {
      return "large";
    } else if (value.length > 30) {
      return "medium";
    } else if (value.length > 10) {
      return "small";
    } else {
      return "mega-small";
    }
  };

  const buildTableHead = (values: any) => {
    let columnsCounter = 0;
    let addColumnForImagePreview = false;
    let headString = `<thead className="thead-dark"><tr><th scope="row">1</th>`;

    for (const property of Object.keys(values[0])) {
      const value = values[0][property];
      if (valueIsImage(value)) {
        addColumnForImagePreview = true;
      }
      headString += `<th scope="col">${property}</th>`;
      editableColumns.push(columnsCounter++);
    }
    if (addColumnForImagePreview) {
      headString += `<th scope="col">Picture</th>`;
    }
    headString += `</tr></thead>`;
    return headString;
  };

  const buildTableBody = (values: any) => {
    let bodyString = `<tbody>`;

    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      bodyString += `<tr><th scope="row">${i + 1}</th>`;

      let imageContent = "";
      for (const property of Object.keys(value)) {
        const cellValue = value[property];
        const columnWidthClass = getCellWidthClass(cellValue);
        const isImage = valueIsImage(cellValue);
        bodyString += `<td scope="col" jsonproperty=${property} class="${columnWidthClass} ${isImage ? "isImage" : ""}">${cellValue}</td>`;
        if (isImage) {
          imageContent = cellValue;
        }
      }

      if (imageContent) {
        bodyString += `<td><img src="${imageContent}" class="pictureImage" style="float:right" width=150 /></td>`;
      }

      bodyString += `</tr>`;
    }

    bodyString += `</tbody>`;
    return bodyString;
  };

  const initializeTable = () => {
    var editableTable = new BSTable("bsTable", {
      editableColumns: editableColumns.join(", "),
      $addButton: $("#new-row-button"),
      onEdit: (e) => {
        updatePictureLink(e);
      },
      onBeforeDelete: () => {
        return DialogService.showConfirmationWindow("Сигурен ли си че искаш да изтриеш?");
      }
    });

    editableTable.init();
  };

  const buildJsonObjectEditor = (property: any) => {
    let propertyContainerString = "<table><tbody>";

    for (const key of Object.keys(property)) {
      if (Array.isArray(property[key])) {
        buildTable(property[key], key);
        initializeTable();
      } else {
        propertyContainerString += `<tr>
        <td><label>${key}</label></td>
        <td><input type="text" jsonproperty='${key}' value="${property[key]}"/></td>
      </tr>`;
      }
    }

    propertyContainerString += "</tbody></table>";
    $("#propertyContainer").append(propertyContainerString);
  };

  const valueIsImage = (value: string) => {
    return /^http/.test(value);
  };

  const updatePictureLink = (tr: any) => {
    const newImageUrl = $(tr).find(".isImage").text();
    if (newImageUrl) {
      const pictureTd = $(tr).find("td").last().prev();
      $(pictureTd).html(`<img src="${newImageUrl}" class="pictureImage" style="float:right" width=150 />`);
    }
  };

  const getTableJson = () => {
    const dataObject: any = {};
    const tableArray: any = [];
    const tableProperty = $("#divContainer").attr("jsonproperty");
    const tableRows = $("#divContainer tbody tr");
    for (const tr of tableRows) {
      const tableDataCells = $(tr).find("td");
      const rowObject = {};
      for (const cell of tableDataCells) {
        const cellProperty = $(cell).attr("jsonproperty");
        if (cellProperty) {
          const cellValue = $(cell).text();
          rowObject[`${cellProperty}`] = cellValue;
        }
      }
      if (!isEmptyRow(rowObject)) {
        tableArray.push(rowObject);
      }
    }
    dataObject[`${tableProperty}`] = tableArray;
    return dataObject;
  };

  const getPropertiesJson = () => {
    const dataObject: any = {};
    var inputs = $("#propertyContainer input");
    for (const input of inputs) {
      const inputProperty = $(input).attr("jsonproperty");
      dataObject[`${inputProperty}`] = $(input).val();
    }
    return dataObject;
  };

  const getJsonObject = () => {
    const tableObject = getTableJson();
    const propertiesObject = getPropertiesJson();
    return { ...tableObject, ...propertiesObject };
  }

  const saveToDatabase = async () => {
    const jsonPropertyName: string = $(`.keyTile.selected:eq(0)`).text();
    const dataObject = getJsonObject();
    disableSaveBtn();
    await new FirebaseService().saveDataObject(jsonPropertyName, dataObject);
  }

  const disableSaveBtn = () => {
    $(".saveButton").attr("disabled", true);
    setTimeout(() => {
      $(".saveButton").attr("disabled", false);
    }, 2000);
  }

  const isEmptyRow = (rowObject: any) => {
    let falseColumnsCounter = 0;
    for (const key of Object.keys(rowObject)) {
      if (!rowObject[key]) {
        falseColumnsCounter++;
      }
    }
    // console.log(rowObject);
    // console.log(Object.keys(rowObject).length === falseColumnsCounter);
    return Object.keys(rowObject).length === falseColumnsCounter;
  }

  const verifyPassword = () => {
    const password = $("#password").val();
    if (password === correctPassword) {
      DialogService.showSuccess("Успешно влизане");
      setPasswordIsCorrect(true);
      sessionStorage.setItem(passwordSessionStorageKey, correctPassword);
    } else {
      DialogService.showError("Грешна парола. Опитай пак!");
      $("#password").val("");
    }
  }

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem(passwordSessionStorageKey);
    if (sessionStorageData === correctPassword) {
      setPasswordIsCorrect(true);
    }
  }, []);

  return (
    <div id="administration">
      {
        passwordIsCorrect ?
          <div className="container">
            <div className="row">
              <div className="col-xs-12 ">
                <div className="about-text">
                  <h2>Админски панел</h2>
                  <div className="tiles-section">
                    {Object.keys(props.database).map((key: any, index: any) => {
                      return <div key={index} className="keyTile" json-property={key} onClick={() => openKeyForm(key)}>{key}</div>;
                    })}
                  </div>
                </div>
                <input type="button" className={"saveButton"} onClick={saveToDatabase} value="Запази промените"></input>
              </div>
              <div className="col-xs-12">
                <div id="propertyContainer"></div>
                <div className="row text-center" id="divContainer"></div>
              </div>
            </div>
          </div> :
          <div id="passwordBox">
            <h3>Въведи парола:</h3>
            <div style={{ marginTop: "20px" }}>
              <input type="password" id="password"></input>
            </div>
            <div>
              <input type="button" value="Влез" onClick={verifyPassword}></input>
            </div>
          </div>
      }
    </div>
  );
};
