import React, { useEffect } from "react";
import * as $ from "jquery";

export interface INavigationProps {
    facebook: string;
    instagram: string;
    phone: string
}

export const Navigation: React.FC<{ data: INavigationProps }> = (props) => {
    useEffect(() => {
        $(".nav-item").on("click", () => {
            $("#navbarMenu").removeClass("show");
        });

        setTimeout(() => {
            if (window.location && window.location.hash) {
                //@ts-ignore
                document.getElementById(window.location.hash.slice(1)).scrollIntoView();
            }
        }, 500);
    }, []);

    return (
        <div>
            <div id="aboveNavPhone">
                За въпроси и поръчки, моля обадете се на телефон {props.data.phone}
            </div>
            <nav id="navigationBar" className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
                <a className="navbar-brand" href="/#"><div id="logo" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div id="navbarMenu" className="collapse navbar-collapse">
                    <div className="navbar-nav">
                        <a className="nav-item nav-link hvr-underline-from-center active" href="/#">Начало</a>
                        {/* <a className="nav-item nav-link hvr-underline-from-center" href="/#about">За нас</a> */}
                        <a className="nav-item nav-link hvr-underline-from-center" href="/#services-banner">Услуги</a>
                        <a className="nav-item nav-link hvr-underline-from-center" href="/#products">Продукти</a>
                        <a className="nav-item nav-link hvr-underline-from-center" href="/#testimonials">Отзиви</a>
                        {/* <a className="nav-item nav-link hvr-underline-from-center" href="/gallery">Галерия</a>
                    <a className="nav-item nav-link hvr-underline-from-center" href="/inspiration">Идеи</a> */}
                        <a className="nav-item nav-link hvr-underline-from-center" href="/blog">Полезно</a>
                        <a className="nav-item nav-link hvr-underline-from-center" href="/#contact">Контакти</a>
                        {/* <a className="nav-item nav-link hvr-underline-from-center" href="/administration">ADMIN</a> */}
                        <div>
                            <a href={props.data ? props.data.facebook : "/"}>
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </div>
                        <div>
                            <a href={props.data ? props.data.instagram : "/"}>
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}
