import React from "react";

export interface ITestimonialProps {
  img: string;
  text: string;
  name: string;
}

export const Testimonials: React.FC<{ data: ITestimonialProps[] }> = (props) => {

  return (
    <div id="testimonials" >
      <div className="container" data-aos="zoom-in-right">
        <div className="section-title text-center">
          <h2>Какво казват нашите клиенти</h2>
        </div>
        <div className="row">

          {props.data ? props.data.map((d, key) => {
            return (
              <div className="col-md-4" key={key}>
                <div className="testimonial">
                  <div className="testimonial-image"> <img src={d.img} alt="" /> </div>
                  <div className="testimonial-content">
                    <div className="star">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <p>"{d.text}"</p>
                    <div className="testimonial-meta"> - {d.name} </div>
                  </div>
                </div>
              </div>);
          }
          ) : "loading"}

        </div>
      </div>
    </div>
  );
}
