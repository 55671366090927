import React from "react";
import { GalleryLightBox } from "./GalleryLightBox";
import * as $ from "jquery";
import { AnimatedButton } from "./AnimatedButton";
import { ILightBoxImage } from "./models/LightBoxImage";

export interface IInspirationProps {
    fullPreview: boolean;
    images: ILightBoxImage[];
}

export const Inspiration: React.FC<IInspirationProps> = (props: IInspirationProps) => {
    const applyHomepageStyles: Function = () => {
        $("#inspiration").css({ "background": "red" });
    };

    if (!props.fullPreview) {
        applyHomepageStyles();
    }

    return (
        <div id="inspiration" className={`text-center general-section ${!props.fullPreview ? "blueBackground" : ""}`}>
            <div data-aos="zoom-in">
                <div className="col-12 section-title">
                    <h2>Идеи за вашия дом</h2>
                    {props.fullPreview && <hr></hr>}
                    <p>Разгледайте идеи изпълнени перфектно</p>
                </div>
                <GalleryLightBox fullPreview={props.fullPreview} images={props.images}/>
                {!props.fullPreview && <AnimatedButton href="/inspiration" text={"Разгледайте всички идеи"} />}
            </div>
        </div>
    );
};