import * as firebase from "firebase/app";
import "firebase/database";
import { DatabaseModel } from "../models/DatabaseModel";
import { VisitedCount } from "../models/VisitedCount";
import DialogService from "./DialogService";

export default class FirebaseService {
    private database: any;
    private sessionStorageKey: string = "dmmontsitedata";

    constructor() {
        const firebaseConfig: any = {
            apiKey: "AIzaSyD2Szumf84bhW_qSS29YWiYbj9H0vkyn7k",
            authDomain: "dm-mont.firebaseapp.com",
            databaseURL: "https://dm-mont.firebaseio.com",
            storageBucket: "dm-mont.appspot.com"
        };
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }

        this.database = firebase.database();
    }

    public async getDatabase() {
        return await this.database.ref("/").once("value");
    }

    public async getAllData(): Promise<DatabaseModel> {
        const sessionStorageData = sessionStorage.getItem(this.sessionStorageKey);
        let dbObject: DatabaseModel;

        if (sessionStorageData) {
            dbObject = JSON.parse(sessionStorageData);
        } else {
            const snapshot = await this.getDatabase();
            sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(snapshot.val()));
            dbObject = snapshot.val();
        }
        this.saveUniqueUser(dbObject.Visitors);
        return  dbObject;
    }

    public async updateSessionStorage() {
        const snapshot = await this.getDatabase();
        sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(snapshot.val()));
        return snapshot.val();
    }

    public async saveDataObject(property: string, dataObject: any, showSweetAlert: boolean = true): Promise<any> {
        if (Object.keys(dataObject).some((key) => key === property)) {
            dataObject = dataObject[property];
        }

        firebase.database().ref(property).set(dataObject)
            .then(() => {
                this.updateSessionStorage();
                if (showSweetAlert) {
                    DialogService.showSuccess("Успешно запазено!");
                }
            }).catch((error) => {
                if (showSweetAlert) {
                    DialogService.showError("Грешка при запазването!");
                }              
            });
    }

    public async saveUniqueUser(visitors: VisitedCount[]) {
        const userHasAlreadyVisitedToday = sessionStorage.getItem("visitedToday");
        if (!userHasAlreadyVisitedToday) {
            const todayDate = this.getTodaysDateString();
            const siteAlreadyHasBeenVisitedToday: VisitedCount = visitors.filter(v => v.date === todayDate)[0];
    
            if (siteAlreadyHasBeenVisitedToday && siteAlreadyHasBeenVisitedToday.count > 0) {
                siteAlreadyHasBeenVisitedToday.count++;
            } else {
                visitors.push({date: todayDate, count: 1});
            }
            await this.saveDataObject("Visitors", visitors, false);
            this.updateSessionStorage();
            sessionStorage.setItem("visitedToday", "true");
        }
    }

    public getTodaysDateString() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        return mm + '/' + dd + '/' + yyyy;
    }
}