import React from "react";

interface IProductImage {
  source: string;
  label: string;
}

export interface IProductProps {
  paragraph: string;
  images: IProductImage[];
}

export const Products: React.FC<{ data: IProductProps }> = (props) => {

  return (
    <div id="products" className="text-center" >
      <div className="container" data-aos="zoom-in">
        <div className="col-12 section-title">
          <h2>Продукти</h2>
          <hr></hr>
          <p>{props.data.paragraph}</p>
        </div>
        <div className="row">
          {props.data.images.map((i: IProductImage, key: any) => {
            return <div className="col-xs-6 col-md-4" key={key}>
              <label className="productCategory">{i.label}</label>
              <img className="product-image" src={i.source} alt="none" />
            </div>;
          })}
        </div>
      </div>
    </div>
  );
};
