import React from "react";
declare var $: any;
export interface IFacebookBannerProps {
    facebook: string;
}

export const FacebookBanner: React.FC<{ data: IFacebookBannerProps }> = (props) => {
    const windowSize = $(window).width();
    let pictureUrl = "https://i.imgur.com/pTCfd9f.png"
    if (+windowSize <= 700) {
        pictureUrl = "https://i.imgur.com/BgYa5sK.jpg"
    }

    return (
            <div className="fbBanner" style={{background: `url(${pictureUrl}) center center no-repeat`, backgroundSize: "contain"}}>
                <a className="fbBanner-link" href={props.data.facebook}></a>
            </div>
    );
};