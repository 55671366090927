import React from "react";

export interface IInstagramBannerProps {
    instagram: string;
}

export const InstagramBanner: React.FC<{ data: IInstagramBannerProps }> = (props) => {
    return (
            <div className="instaBanner container-fluid">
                <div className="instaBanner-heading">Сподели своя стил с <span><a href={props.data.instagram}>#DMMONT</a></span> и може да си следващата звезда в нашия <span><a href={props.data.instagram}>Instаgram</a></span>!</div>
                <div className="instabanner-pictures row">
                    <img className="col-6 col-md-2 instaPic" src={require("./resources/images/instagram/1.jpg")}/>
                    <img className="col-6 col-md-2 instaPic" src={require("./resources/images/instagram/2.jpg")}/>
                    <img className="col-6 col-md-2 instaPic" src={require("./resources/images/instagram/3.jpg")}/>
                    <img className="col-6 col-md-2 instaPic" src={require("./resources/images/instagram/4.jpg")}/>
                    <img className="col-6 col-md-2 instaPic" src={require("./resources/images/instagram/5.jpg")}/>
                    <img className="col-6 col-md-2 instaPic" src={require("./resources/images/instagram/6.jpg")}/>
                </div>
            </div>
    );
};