import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "jquery/dist/jquery.min.js";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import { About } from "./About";
import "./App.css";
import { Blog } from "./Blog";
import { Contact } from "./Contact";
import { Gallery } from "./Gallery";
import { Header } from "./Header";
import { Inspiration } from "./Inspiration";
import { Navigation } from "./Navigation";
import { Products } from "./Products";
import data from "./resources/data/data.json";
import { Services } from "./Services";
import { Testimonials } from "./Testimonials";
import FirebaseService from "./services/FirebaseService";
import { DatabaseModel } from "./models/DatabaseModel";
import { Administration } from "./Administration";
import { FacebookBanner } from "./FacebookBanner";
import { InstagramBanner } from "./InstagramBanner";

export const App: React.FC = () => {
  const [database, setDatabase] = useState<any>(null);

  const getDataPromise = async () => {
    const database: DatabaseModel = await new FirebaseService().getAllData();
    // console.log(database);
    setDatabase(database);
  };

  useEffect(() => {
    AOS.init();
    getDataPromise();
  }, []);

  if (!database) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/gallery">
            <Navigation data={database.Contact} />
            <SimpleReactLightbox>
              <Gallery fullPreview={true} images={database.Gallery} />
            </SimpleReactLightbox>
          </Route>
          <Route path="/inspiration">
            <Navigation data={database.Contact} />
            <SimpleReactLightbox>
              <Inspiration fullPreview={true} images={database.Inspiration} />
            </SimpleReactLightbox>
          </Route>
          <Route path="/blog">
            <Navigation data={database.Contact} />
            <Blog fullPreview={true} blogPosts={database.Blog} />
          </Route>
          <Route path="/admin">
            <Navigation data={database.Contact} />
            <Administration database={database} />
          </Route>
          <Route path="/">
            <Navigation data={database.Contact} />
            <Header data={database.Header} />
            <Services data={database.Services} />
            <FacebookBanner data={database.Contact} />
            <Products data={database.Products} />
            <Testimonials data={database.Testimonials} />
            <InstagramBanner data={database.Contact} />
            {/* <SimpleReactLightbox>
              <Gallery fullPreview={false} images={database.Gallery} />
            </SimpleReactLightbox>
            <SimpleReactLightbox>
              <Inspiration fullPreview={false} images={database.Inspiration} />
            </SimpleReactLightbox> */}
            {/* <About data={database.About} /> */}
            <Blog fullPreview={false} blogPosts={database.Blog} />
            <Contact data={database.Contact} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
