import React from "react";
import { GalleryLightBox } from "./GalleryLightBox";
import { AnimatedButton } from "./AnimatedButton";
import { ILightBoxImage } from "./models/LightBoxImage";

export interface IGalleryProps {
    fullPreview: boolean;
    images: ILightBoxImage[];
}

export const Gallery: React.FC<IGalleryProps> = (props: IGalleryProps) => {
    return (
        <div id="gallery" className="text-center general-section" data-aos="zoom-in">
            <div className="col-12 section-title">
                <h2>Галерия</h2>
                <hr></hr>
                <p>Разгледайте снимки от наши монтажи</p>
            </div>
            <GalleryLightBox fullPreview={props.fullPreview} images={props.images} />
            {!props.fullPreview && <AnimatedButton href="/gallery" text={"Разгледайте цялата галерия"} />}
        </div>
    );
};