import React from "react";
import * as $ from "jquery";
import { SRLWrapper } from "simple-react-lightbox";
import { ILightBoxImage } from "./models/LightBoxImage";

interface IGalleryLightBox {
    fullPreview: boolean;
    images: ILightBoxImage[];
}

export const GalleryLightBox: React.FC<IGalleryLightBox> = (props: IGalleryLightBox) => {
    const callbacks: object = {
        onLightboxOpened: object => $("#navigationBar").hide(),
        onLightboxClosed: object => $("#navigationBar").show(),
    };

    const options = {
        settings: {
            overlayColor: "#293745",
            autoplaySpeed: 1500,
            transitionSpeed: 900,
            disableKeyboardControls: true,
            disableWheelControls: true,
            disablePanzoom: true,
            lightboxTransitionSpeed: 0.5,
            lightboxTransitionTimingFunction: "easeIn",
            slideAnimationType: "slide",
            slideSpringValues: [300, 200],
            slideTransitionSpeed: 0.6,
            slideTransitionTimingFunction: "easeIn",
        },
        buttons: {
            backgroundColor: "#5380ad",
            iconColor: "#fff",
            showAutoplayButton: false,
            showFullscreenButton: false,
            showThumbnailsButton: false,
            showDownloadButton: false,
        },
        caption: {
            captionColor: "#dbdbdb",
            captionFontFamily: "Raleway, sans-serif",
            captionFontWeight: "300",
        }
    };

    const renderImages = (fullPreview: boolean) => {
        const imagesObject: ILightBoxImage[] = props.images;
        if (fullPreview) {
            return (imagesObject.map((i, key) =>
                <a href={i.sourceUrl} data-attribute="SRL"  key={key}>
                    <img src={i.sourceUrl} alt={i.caption} />
                </a>
            ));
        } else {
            return (imagesObject.slice(0, 4).map((i, key) =>
                <a href={i.sourceUrl} data-attribute="SRL" key={key}>
                    <img src={i.sourceUrl} alt={i.caption} />
                </a>
            ));
        }
    };

    return (
        <SRLWrapper callbacks={callbacks} options={options} >
            {renderImages(props.fullPreview)}
        </SRLWrapper>
    );
};