import React from "react";
import { GoogleMaps } from "./GoogleMaps";
import { About } from "./About";

export interface IContactProps {
  address: string;
  workHours: [{
    weekdayHours: string,
    saturdayHours: string,
  }];
  phone: string;
  email: string;
  facebook: string;
  instagram: string;
  youtube: string;
  paragraph: string;
}

export const Contact: React.FC<{ data: IContactProps }> = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container" data-aos="zoom-in-right">
          <div className="row">
            <div className="col-xs-12">
              <div className="about-text">
                <h2>За нас</h2>
                <p>{props.data.paragraph ?? "loading..."}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="section-title">
                <h2>Свържете се с нас</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-md-offset-1 contact-info">
              <div className="contact-item">
                <p><span><i className="fa fa-map-marker"></i> Адрес</span>{props.data ? props.data.address : "loading"}</p>
              </div>
              <div className="contact-item">
                <p><span><i className="fas fa-clock"></i> Работно време</span></p>
                <p>Понеделник - Петък: {props.data.workHours.length > 0 ? props.data.workHours[0].weekdayHours : ""}</p>
                <p>Събота: {props.data.workHours.length > 0 ? props.data.workHours[0].saturdayHours : ""}</p>
              </div>
              <div className="contact-item">
                <p><span><i className="fas fa-phone-alt"></i> Телефон</span> {props.data ? props.data.phone : "loading"}</p>
              </div>
              <div className="contact-item">
                <p><span><i className="fas fa-envelope"></i> Email</span> {props.data ? props.data.email : "loading"}</p>
              </div>
              <div className="contact-item social">
                <div className="followUs">Последвайте ни!</div>
                <ul style={{marginTop: "10px"}}>
                  <li><a href={props.data ? props.data.facebook : '/'}><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href={props.data ? props.data.instagram : '/'}><i className="fab fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <GoogleMaps />
            </div>
          </div>
        </div>
      </div>
      {/* <div id="footer"></div>   */}
    </div>
  );
}
